<template>
  <div class="container">
    <div class="row mb-5">
      <div class="col-12">
        <h1>Terms &amp; Conditions</h1>
        <h6 class="text-muted">Updated at 2020-08-26</h6>
        <h3>License</h3>
        <p>Excelerate grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the website strictly in accordance with the terms of this Agreement.</p>
        <h3>Meanings</h3>
        <h4>For this Privacy Policy:</h4>
        <ul>
          <li>You is referred to the person who accessed the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
          <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Excelerate ApS, Strandlodsvej 44, 3 Floor, 2300 Copenhagen, Denmark
          Website is referred to www.excelerate.careers and www.excelerate.dk</li>
          <li>Service is referred to the website.</li>
          <li>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
          <li>Third-party Social Media Service could be any website or social network website in which a User can log in or create an account to use the Service.</li>
          <li>Personal Data is any information that relates to an identified or identifiable individual.</li>
          <li>Cookies are a small amount of data generated by a website and saved by your web browser. Its purpose is to remember information about you, similar to a preference file created by a software application.</li>
          <li>Device could be any device with internet who can access the service such as a mobile phone, a computer or a digital tablet.</li>
          <li>Usage Data it’s referred to the data that’s been collected automatically, either generated by the use of the Service or from the Service infrastructure itself. For example, how many times the user accessed the website.
        Restrictions</li>
        </ul>

        <h4>You agree not to, and you will not permit others to:</h4>
        <ul>
          <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the website or make the platform available to any third party.</li>
          <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the website.</li>
          <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Excelerate or its affiliates, partners, suppliers or the licensors of the website.</li>
        </ul>

        <h3>Return and Refund Policy</h3>
        <p>
        If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.
        </p>
        <h4>Your Suggestions</h4><p>
        Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to Excelerate with respect to the website shall remain the sole and exclusive property of Excelerate.<br>
        Excelerate shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.
        </p>
        <h4>Your Consent</h4>
        <p>
        By using our site, registering an account, or making a purchase, you consent to this Terms $ Conditions.
        </p>

        <h4>Links to Other Websites</h4>
        <p>
        Our service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Terms $ Conditions of every site You visit.<br>
        We have no control over and assume no responsibility for the content, Terms $ Conditions or practices of any third party sites or services.
        </p>

        <h4>Cookies</h4>
        <p>
        Excelerate uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies. By using our site, registering an account, or making a purchase, you consent to the fact that we use cookies.
        </p>
        <h4>Changes To Our Terms &amp; Conditions</h4>
        <p>
        You acknowledge and agree that Excelerate may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at Excelerate’s sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform Excelerate when you stop using the Service. You acknowledge and agree that if Excelerate disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account.<br>
        If we decide to change our Terms $ Conditions, we will post those changes on this page, and/or update the Terms $ Conditions modification date below.
        </p>

        <h4>Modifications to Our website</h4>
        <p>
        Excelerate reserves the right to modify, suspend or discontinue, temporarily or permanently, the website or any service to which it connects, with or without notice and without liability to you.
        </p>

        <h4>Updates to Our website</h4>
        <p>
        Excelerate may from time to time provide enhancements or improvements to the features/ functionality of the website, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").<br>
        Updates may modify or delete certain features and/or functionalities of the website. You agree that Excelerate has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the website to you.<br>
        You further agree that all Updates will be (i) deemed to constitute an integral part of the website, and (ii) subject to the terms and conditions of this Agreement.
        </p>

        <h4>Third-Party Services</h4>
        <p>
        We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").<br>
        You acknowledge and agree that Excelerate shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Excelerate does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.<br>
        Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.
        </p>

        <h4>Term and Termination</h4>
        <p>
        This Agreement shall remain in effect until terminated by you or Excelerate.<br>
        Excelerate may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.<br>
        This Agreement will terminate immediately, without prior notice from Excelerate, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the website and all copies thereof from your computer.<br>
        Upon termination of this Agreement, you shall cease all use of the website and delete all copies of the website from your computer.<br>
        Termination of this Agreement will not limit any of Excelerate's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.
        </p>

        <h4>Indemnification</h4>
        <p>
          You agree to indemnify and hold Excelerate and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the website; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.
        </p>

        <h4>No Warranties</h4>
        <p>
        The website is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Excelerate, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the website, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, Excelerate provides no warranty or undertaking, and makes no representation of any kind that the website will meet your requirements, achieve any intended results, be compatible or work with any other software, , systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.<br>
        Without limiting the foregoing, neither Excelerate nor any Excelerate's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the website, or the information, content, and materials or products included thereon; (ii) that the website will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the website; or (iv) that the website, its servers, the content, or e-mails sent from or on behalf of Excelerate are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.<br>
        Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.
        </p>

        <h4>Limitation of Liability</h4>
        <p>
        Notwithstanding any damages that you might incur, the entire liability of Excelerate and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the website.<br>
        To the maximum extent permitted by applicable law, in no event shall Excelerate or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the website, third-party software and/or third-party hardware used with the website, or otherwise in connection with any provision of this Agreement), even if Excelerate or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.<br>
        Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.
        </p>
        <h4>Severability</h4>
        <p>
        If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
        </p>
        <h4>Waiver</h4>
        <p>
        Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.
        </p>

        <h4>Amendments to this Agreement</h4>
        <p>
        Excelerate reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br>
        By continuing to access or use our website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Excelerate.
        </p>

        <h4>Our responsibility</h4>
        <p>
        You agree to that Excelerate has no responsibility towards the collaboration between the parties using our platform. Excelerate does not promise at any point that the collaboration between parties is legally binding in any way. The two parties are individually responsible for the actual collaboration to happen. Excelerate only facilitates the matching and the contact and we do so, to the best of our ability. 
        </p>

        <h4>The data</h4>
        <p>
        You agree to Excelerate owning all user data we collect via the website. Excelerate can directly or indirectly sell, share or give the data to any third party. The user data included everything you as a user type into any form or input, and behaviour on the website.
        </p>
        <h4>Entire Agreement</h4>
        <p>
        The Agreement constitutes the entire agreement between you and Excelerate regarding your use of the website and supersedes all prior and contemporaneous written or oral agreements between you and Excelerate.<br>
        You may be subject to additional terms and conditions that apply when you use or purchase other Excelerate's  services, which Excelerate will provide to you at the time of such use or purchase.
        </p>

        <h4>Disclaimer</h4>
        <p>
        Excelerate is not responsible for any content, code or any other imprecision.<br>
        Excelerate does not provide warranties or guarantees.<br>
        In no event shall Excelerate be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
        </p>

        <h4>Contact Us</h4>
        <p>
        Don't hesitate to contact us if you have any questions.<br>
        - Via Email:  info@excelerate.careers
        </p>
        <br><br><br><br>
      </div>
    </div>

  </div>
</template>

<script>

export default {


    data: function() {
        return {
          
        }
    }
}
</script>
